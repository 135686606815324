import axios from "axios";
import { getAuthToken } from "./auth";

export const createAxiosInstance = (baseURL?: string) => {
    const instance = axios.create({
        baseURL: baseURL || "https://szeohm1sw1.execute-api.us-west-2.amazonaws.com/beta/",
        headers: {
            "content-type": "application/json"
        }
    });

    instance.interceptors.request.use(async (config) => {
        config.headers.Authorization = await getAuthToken();
        return config;
    });

    return instance;
}