import { ResourcesConfig } from "@aws-amplify/core";

const config: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: "us-west-2_rZ3P89BMh",
            userPoolClientId: "6dm0scd22o0fgebl3585jo92fh",
            loginWith: {
                oauth: {
                    domain: "gru-web-beta.auth.us-west-2.amazoncognito.com",
                    redirectSignIn: [window.location.origin],
                    redirectSignOut: [window.location.origin],
                    responseType: "code",
                    scopes: ["openid"]
                }
            }
        }
    }
}

export default config;